import { extendTheme } from "@chakra-ui/react";

import Button from "./Button";
import { fontFamily } from "html2canvas/dist/types/css/property-descriptors/font-family";
import { color } from "framer-motion";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { BiFontSize } from "react-icons/bi";

const colors = {
  primaryScheme: {
    50: "#fcf6ba",
    100: "#faf089",
    200: "#f8ed71",
    300: "#f7e958",
    400: "#f6e640",
    500: "#F4E00F",
    600: "#c7b609",
    700: "#afa008",
    800: "#968a07",
    900: "#655d05",
  },
  black: {
    500: "#000000", // Adjust the hex code if needed
  },
  primary: "#FFFF00",
  primaryBlack: "#141414",
  pageBg: "#FAFAFA",
  navBg: "#F8FAFD",
  gradient1: "#e3af32",
  gradient2: "#EBD118",
  textBlack: "#141414",
  textSecondary: "#1414148F",
  textGray: "#585858",
  border: "#1414141F",
  btnBorder: "#F4E00F",
  primaryBorder: "#FFDD00",
};

const theme = extendTheme({
  colors,
  components: {
    Button,
    Alert: {
      variants: {
        warning: {
          // Define a custom variant for warning
          container: {
            background: "linear-gradient(#f5e003, #e6c200)",
          },
        },
        success: {
          container: {
            backgroundColor: "#2D9017",
          },
        },
      },
      baseStyle: {
        container: {
          color: "black",
          fontFamily: "Avantt light", // Set your custom font family

          fontWeight: "600", // Set your custom font weight
          fontSize: "15px", // Set your custom font size
        },
        title: {
          fontFamily: "Avantt semibold", // Set your custom font family

          fontWeight: "700", // Make the title bolder
          fontSize: "18px", // Set your custom font size
        },
      },
    },
    Container: {
      baseStyle: {
        maxW: "1600px",
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "sm",
        marginBottom: "4px",
        fontWeight: "600",
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "Avantt",
        color: "primaryBlack",
      },
    },
    Heading: {
      baseStyle: {
        color: "white",
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "primaryBlack",
      },
    },
    CardHeader: {
      baseStyle: {
        color: "red",
      },
    },
    Switch: {
      baseStyle: {
        track: {
          bg: "black",
          _checked: {
            bg: "black",
          },
        },
        thumb: {
          _checked: {
            bg: "white",
          },
        },
      },
    },
  },
  shadows: {
    outline: "black",
    light1: "0px 0px 4px 0px #00000029",
    light4: "0px 0px 16px 0px #00000029",
  },

  layerStyles: {
    paper: {
      bg: "white",
      boxShadow: "0px 0px 4px 0px #00000029",
      borderRadius: "16px",
      padding: "24px",
    },
    gradient: {
      bg: "linear-gradient(0deg, #e3af32 0%, #EBD118 100%)",
    },
  },
});
export default theme;
