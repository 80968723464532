import sixth from "innovate/assets/images/StakeHolders/ArneRust-modified.png";
import fifth from "innovate/assets/images/StakeHolders/GustavoAraujoDeCastro-modified.png";
import world1 from "innovate/World2.json";
import React, { useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

interface GeoJson {
  type: string;
  features: {
    type: string;
    properties: {
      NAME: string;
    };
    geometry: {
      type: string;
      coordinates: number[][][][];
    };
  }[];
}

interface CountryData {
  countryName: string;
  name: string;
  position: string;
  coordinates: [number, number];
}

const world = world1 as GeoJson;

const ApicountryData: CountryData[] = [
  {
    countryName: "Brazil",
    name: "Gustavo Araujo de Castro",
    position: "DIRETOR (A) DE MARKETING",
    coordinates: [-55.491977, -10.836389],
  },
  {
    countryName: "South Africa",
    name: "Arné Rust",
    position: "INNOVATIONS DIRECTOR",
    coordinates: [25.083901, -29.000341],
  },
];

const calculateCentroid = (coordinates: number[][][][]): [number, number] => {
  let totalX = 0;
  let totalY = 0;
  let totalPoints = 0;

  coordinates.forEach((polygon) => {
    polygon.forEach((ring) => {
      ring.forEach((point) => {
        if (point) {
          totalX += point[0];
          totalY += point[1];
          totalPoints += 1;
        }
      });
    });
  });

  return [totalX / totalPoints, totalY / totalPoints];
};

const WorldMap: React.FC = () => {
  const [countryData, setCountryData] = useState<CountryData[]>([]);

  useEffect(() => {
    const newCountryData: CountryData[] = ApicountryData.map((data) => {
      const feature = world.features.find(
        (item) => item.properties.NAME === data.countryName,
      );
      if (!feature) return null;

      const centroid = calculateCentroid(feature.geometry.coordinates);
      return {
        countryName: feature.properties.NAME,
        coordinates: centroid,
        name: data.name,
        position: data.position,
      };
    }).filter((data) => data !== null) as CountryData[];

    setCountryData(newCountryData);
  }, []);

  return (
    <ComposableMap
      projectionConfig={{ rotate: [-11, 0, 0], scale: 205 }}
      height={551}
      style={{ height: "auto", width: "100%" }}
      width={980}
    >
      <Geographies geography={world1}>
        {({ geographies }) =>
          geographies.map((geography) => (
            <Geography
              key={`${geography.properties.NAME}`}
              geography={geography}
              style={{
                default: {
                  fill: "#5B5B5B",
                  outline: "none",
                  stroke: "#5B5B5B",
                  strokeWidth: 0.75,
                },
                hover: {
                  fill: "#5B5B5B",
                  outline: "none",
                  stroke: "#5B5B5B",
                  strokeWidth: 0.75,
                },
                pressed: {
                  fill: "#5B5B5B",
                  outline: "none",
                  stroke: "#5B5B5B",
                  strokeWidth: 0.75,
                },
              }}
            />
          ))
        }
      </Geographies>
      {countryData.map((data) => (
        <Marker key={data.name} coordinates={data.coordinates} fill="#777">
          <>
            <text
              textAnchor="start"
              fill="#FFFFFF"
              dy={2}
              dx={40}
              fontSize="14px"
              fontWeight={700}
            >
              {data.name}
            </text>
            <text
              textAnchor="start"
              fill="#F1D513"
              dy={20}
              dx={40}
              fontSize="12.4px"
              fontWeight={600}
            >
              {data.countryName}
            </text>
            <text
              textAnchor="start"
              fill="#FFFFFF99"
              dy={34}
              dx={40}
              fontSize="10.4px"
              fontWeight={600}
            >
              {data.position}
            </text>
            <image
              href={data.countryName === "Brazil" ? fifth : sixth}
              width="40"
              height="40"
              x="-6"
              y="-8"
            />
          </>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default WorldMap;
