import { string } from "@amcharts/amcharts4/core";
import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { Select } from "chakra-react-select";
import copyImage from "innovate/assets/images/copy.png";
import deleteImage from "innovate/assets/images/delete.png";
import editImage from "innovate/assets/images/edit.png";
import vector from "innovate/assets/images/select_all.png";
import share from "innovate/assets/images/share.svg";
import { RiSendPlane2Fill } from "react-icons/ri";

export const clientId =
  import.meta.env.VITE_CLIENT_ID ?? "5d7c1d57-b6fd-4f46-bd7c-c9589ebdfef1";
export const redirectUri =
  import.meta.env.VITE_REDIRECT_URI ?? "https://innovatex.ai-stg.ab-inbev.com";
export const fixed =
  import.meta.env.VITE_API_URL ??
  "https://innovate-backend.azurewebsites.net/api/";

export const chakraStyles = (
  customWidth: string,
  customFont: string,
  customAlign: string
) => ({
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: customWidth,
    fontSize: customFont,
    fontWeight: "600",
    textAlign: customAlign,
    color: "black",
    bg: "white",
    padding: "0 16px 0",
    backgroundColor: "white !important",
    // border: "none",
    borderColor: state.isFocused
      ? "primaryBlack !important"
      : "border !important",
    borderRadius: "8px",
    boxShadow: "none !important",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#1E1E1ECC",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    px: "0",
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    bg: "border",
    fontSize: "12px",
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    px: "200px",
    bg: "green",
    cursor: "pointer",
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: "textBlack !important",
    fontSize: "12px",
    background: state.isFocused ? "border !important" : "none",
  }),
});
export const filterInfo: { [key: string]: string } = {
  // Brand: "Brand Name",
  // "Beer Type": "Type of beer",
  // "Pack Size": "Volume size of the pack. i.e. 330 ml",
  // "Price Segment": "SKU falling in the four core price segments",
  // ABV: "Alcohol by volume %",
  // "Pack/Return Type": "Container type of the pack",
  // "Launch Period": "Which quarter innovation is going to be launched?",
  // Districts: "Targeted district for the innovation to be launched",
  // "POC Segment": "Segments where innovation is planning to be launched",
  "Price per HL": "Expected NR per HL",
  "MACO per HL": "Unplugged MACO/HL",
  Distribution: "% of POC where the product will be available",
};

export const filterRange = [
  {
    MARKET: "Brazil",
    P_Category: "BEER",
    R_Price_per_HL: 210,
    R_Distribution: 92,
    P_ABV: 8,
  },
  {
    MARKET: "Brazil",
    P_Category: "BEYOND BEER",
    R_Price_per_HL: 392,
    R_Distribution: 93,
    P_ABV: 8,
  },
  {
    MARKET: "Brazil",
    P_Category: "NAB",
    R_Price_per_HL: 281,
    R_Distribution: 96,
    P_ABV: 0,
  },
  {
    MARKET: "Brazil",
    P_Category: "NON ALC BEER",
    R_Price_per_HL: 35,
    R_Distribution: 92,
    P_ABV: 0,
  },
  {
    MARKET: "South Africa",
    P_Category: "BEER",
    R_Price_per_HL: 160,
    R_Distribution: 91,
    P_ABV: 8,
  },
  {
    MARKET: "South Africa",
    P_Category: "BEYOND BEER",
    R_Price_per_HL: 260,
    R_Distribution: 95,
    P_ABV: 8,
  },
];

export const DistricDummy = [
  {
    name: "CEN",
    children: [
      { name: "NORTH sssssddWEST", value: 100 },
      { name: "VAAL MALUTI", value: 60 },
      { name: "HIGHVELD", value: 30 },
      { name: "VAAL MdddddALUT2I", value: 60 },
      { name: "HIGHVELDf", value: 30 },
    ],
  },
  {
    name: "CAP",
    children: [
      { name: "LIMPOPO", value: 135 },
      { name: "JHB", value: 98 },
      { name: "LIMPOPO3", value: 56 },
    ],
  },
  {
    name: "ECR",
    children: [
      { name: "C-1ataLIMPOPO", value: 335 },
      { name: "C1ataLIMPOPO-2", value: 148 },
    ],
  },
  {
    name: "OTHER",
    children: [
      { name: "OTHER", value: 415 },
      //   { name: "D-aLIMPOPO2", value: 148 },
      //   { name: "D-aLIMPOPO3", value: 89 },
    ],
  },
];

export const POCDummy = [
  {
    name: "MAINSTREAM TAVERN",
    children: [{ name: "MAINSTREAM TAVERN", value: 100 }],
  },

  {
    name: "MAINSTREAM TAVERN",
    children: [{ name: "MAINSTREAM TAVERN", value: 135 }],
  },
  {
    name: "SELF SERVICE",
    children: [{ name: "SELF SERVICE", value: 335 }],
  },
  {
    name: "PREMIUM BAR",
    children: [
      { name: "PREMIUM BAR", value: 415 },
    ],
  },
  {
    name: "WHOLESALER",
    children: [
      { name: "WHOLESALER", value: 415 },
    ],
  },
  {
    name: "MAINSTREAM BAR",
    children: [
      { name: "MAINSTREAM BAR", value: 415 },
      //   { name: "D-aLIMPOPO2", value: 148 },
      //   { name: "D-aLIMPOPO3", value: 89 },
    ],
  },
  {
    name: "PREMIUM TAVERN",
    children: [
      { name: "PREMIUM TAVERN", value: 415 },
      //   { name: "D-aLIMPOPO2", value: 148 },
      //   { name: "D-aLIMPOPO3", value: 89 },
    ],
  },
  {
    name: "OTHERS",
    children: [
      { name: "OTHERsssssssssS", value: 415 },
      //   { name: "D-aLIMPOPO2", value: 148 },
      //   { name: "D-aLIMPOPO3", value: 89 },
    ],
  },
  {
    name: "COUNTER SERVICE",
    children: [
      { name: "COUNTER SERVICE", value: 415 },
      //   { name: "D-aLIMPOPO2", value: 148 },
      //   { name: "D-aLIMPOPO3", value: 89 },
    ],
  },
];
export const NewPOCDummy = [
  {
    name: "MAINSTREAM TAVERN",
    value: 100,
  },
  {
    name: "MAINSTREAM TAVERN",
    value: 135,
  },
  {
    name: "SELF SERVICE",
    value: 335,
  },
  {
    name: "PREMIUM BAR",
    value: 415,
  },
  {
    name: "WHOLESALER",
    value: 415,
  },
  {
    name: "MAINSTREAM BAR",
    value: 415,
  },
  {
    name: "PREMIUM TAVERN",
    value: 415,
  },
  {
    name: "OTHERS",
    value: 415,
  },
  {
    name: "COUNTER SERVICE",
    value: 415,
  },
];



export const RegionalColumn = [
  {
    header: "Districts",
    accessorKey: "R_Districts",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
  {
    header: "POC Segments",
    accessorKey: "R_POC_Segments",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
];
export const BrazilRegionalColumn = [
  {
    header: "Regions",
    accessorKey: "R_Regions",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
  {
    header: "Channels",
    accessorKey: "R_Channels",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
  {
    header: "NR per HL (USD)",
    accessorKey: "R_Price_per_HL",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
  {
    header: "Distribution %",
    accessorKey: "R_Distribution",
    cell: (props: { getValue: () => string }) => (
      <Text whiteSpace={"normal"}> {props.getValue()}</Text>
    ),
  },
];

const renderScenarioNameCell = (
  props: any,
  SetnewName: any,
  SetRenameId: any,
  saveScenarioNameChange: any,
  newName: any
) => (
  <InputGroup>
    <Input
      fontSize="14px"
      fontWeight="bold"
      defaultValue={props.getValue()}
      onChange={(e) => {
        SetnewName(e.target.value);
      }}
      onFocus={() => SetRenameId(props.row.original.id)}
    />
    <InputRightElement
      onClick={() => {
        saveScenarioNameChange();
      }}
    >
      <IconButton
        cursor={newName.length < 1 ? "not-allowed" : "grab"}
        size="sm"
        variant="outline2"
        marginX={0.5}
        aria-label="Go to first page"
        icon={<RiSendPlane2Fill />}
        title={newName}
      />
    </InputRightElement>
  </InputGroup>
);
const renderActionCell = (
  info: any,
  setIsApprove: any,
  onOpen: any,
  getScenarioId: any,
  setCurrentId: any,
  popOpenStates: any,
  togglePopover: any,
  userOptions: any,
  handleShare: any,
  userValue: any,
  selectProps: any
) => (
  <>
    <Button
      title="Edit"
      bg="none"
      onClick={() => console.log("Button 1 clicked")}
    >
      <Image src={editImage} alt="edit" />
    </Button>
    <Button
      title="Copy"
      bg="none"
      onClick={() => {
        setIsApprove(true);
        onOpen();
        getScenarioId(info.row.original.id);
      }}
    >
      <Image src={copyImage} alt="copy" />
    </Button>
    <Button
      title="Delete"
      bg="none"
      onClick={() => {
        setIsApprove(false);
        onOpen();
        getScenarioId(info.row.original.id);
      }}
    >
      <Image src={deleteImage} alt="delete" />
    </Button>

    <Popover
      isOpen={popOpenStates[info.row.original.id]}
      onClose={() => togglePopover(info.row.original.id)}
    >
      <PopoverTrigger>
        <Button
          title="share"
          bg="none"
          onClick={() => {
            togglePopover(info.row.original.id);
            getScenarioId(info.row.original.id);
            setCurrentId(info.row.original.id);
          }}
        >
          <Image src={share} alt="share" />
        </Button>
      </PopoverTrigger>
      <PopoverContent w="250px">
        <PopoverBody p={4}>
          <Flex mb={2} alignItems="center" justifyContent="center">
            <Image src={share} />
            <Text color="black" ml={2}>
              Share Scenario
            </Text>
          </Flex>
          <Flex alignItems="center" justifyContent="center" gap={1}>
            <Select
              size="sm"
              options={userOptions as any[]}
              className="shareUser"
              classNamePrefix="shareUser"
              placeholder="Select User"
              controlShouldRenderValue={false}
              useBasicStyles
              value={userValue}
              {...selectProps}
            />
            <Button variant="outline" bg="#191919" onClick={handleShare}>
              <Text color="#FFFFFF">Share</Text>
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  </>
);

const columnHelper = createColumnHelper<any>();

export const PrLaunchColumns = (
  isShared: any,
  setIsApprove: any,
  onOpen: any,
  getScenarioId: any,
  setCurrentId: any,
  popOpenStates: any,
  togglePopover: any,
  userOptions: any,
  handleShare: any,
  userValue: any,
  selectProps: any,
  SetnewName: any,
  SetRenameId: any,
  saveScenarioNameChange: any,
  newName: any
) => [
  columnHelper.accessor((row) => row, {
    id: "checkbox",
    cell: (info) => <Checkbox colorScheme="black" borderColor="black" />,
    header: () => <Image src={vector} m={1} />,
  }),
  {
    header: "Scenario Name",
    accessorKey: "scenario_name",
    cell: (props: any) =>
      renderScenarioNameCell(
        props,
        SetnewName,
        SetRenameId,
        saveScenarioNameChange,
        newName
      ),
  },
  ...(isShared
    ? [
        {
          header: "Created By",
          accessorKey: "Created By",
          cell: (props: { getValue: () => string }) => <p> Michel</p>,
        },
      ]
    : []),

  {
    header: "Brand",
    accessorKey: "brand",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Incremental Volume %",
    accessorKey: "incremental_volume_%",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Incremental NR",
    accessorKey: "incremental_nr",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    header: "Incremental MaCo",
    accessorKey: "incremental_maco",
    cell: (props: { getValue: () => string }) => <p> {props.getValue()}</p>,
  },
  {
    accessor: (row: any) => row.id,
    id: "Action",
    cell: (info: any) =>
      renderActionCell(
        info,
        setIsApprove,
        onOpen,
        getScenarioId,
        setCurrentId,
        popOpenStates,
        togglePopover,
        userOptions,
        handleShare,
        userValue,
        selectProps
      ),
    header: () => "Actions",
  },
];
