import { Box, Image } from "@chakra-ui/react";
import footer from "innovate/assets/images/footer.png";
import PreloadBack from "innovate/assets/images/PreloadBack.png";

const Layout = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    minHeight="100vh"
    bgImage={`url(${PreloadBack})`}
    bgSize="cover"
    bgPosition="center"
  >
    <Box position="relative" flex="1" zIndex={99}>
      {children}
    </Box>
    <Image
      src={footer}
      w="100%"
      position="absolute"
      bottom={-1}
      mt={8}
      height="22vh"
    />
  </Box>
);

export default Layout;
