
const Button = {
  baseStyle: {
    // borderRadius: 'full',
    // fontWeight: '700',
  },
  size: {
    sm: {
      height: "32px",
      px: "16px",
      fontSize: "14px",
      width: "auto",
    },
    md: {
      height: "40px",
      px: "16px",
      fontSize: "16px",
      width: "auto",
    },
    lg: {
      height: "48px",
      px: "24px",
      fontSize: "18px",
      width: "auto",
    },
  },
  variants: {
    primary: {
      background: "primaryBlack",
      color: "white",
      fontFamily: "Avantt",

      _hover: {
        color: "primary",
      },
      _active: {
        color: "textGray",
      },
    },
    secondary: {
      border: "1px solid",
      borderColor: "primaryBlack",
      color: "primaryBlack",
      fontFamily: "Avantt",

      _hover: {
        bg: "primary",
      },
      _active: {
        color: "#14141466",
      },
    },
    tertiary: {
      bg: "#eaeaea",
      fontFamily: "Avantt",

      // border: "1px solid #000",
      color: "#00000026",
      fontSize: "14px",
      fontWeight: "700",
      transition: "all 0.2s ease-in-out",
      _hover: {
        background: "linear-gradient(#f5e003, #e5b611)",
        color: "white",
      },
    },
    outline: {
      background: "#191919",
      border: "1px solid #000",
      color: "#00000026",
      fontFamily: "Avantt",

      fontSize: "14px",
      fontWeight: "700",
      transition: "all 0.2s ease-in-out",
      _hover: {
        background: "linear-gradient(#f5e003, #e5b611)",
      },
    },
    outline2: {
      background: "#fff",
      // border: '1px solid #000',
      color: "#000",
      fontFamily: "Avantt",

      fontSize: "14px",
      fontWeight: "500",
      transition: "all 0.2s ease-in-out",
      _hover: {
        background: "linear-gradient(#f5e003, #e5b611)",
      },
    },
    green: {
      background: "#508A3B",
      border: "1px solid #000",
      color: "#00000026",
      fontFamily: "Avantt",

      fontSize: "14px",
      fontWeight: "700",
      transition: "all 0.2s ease-in-out",
      _hover: {
        background: "linear-gradient(#f5e003, #e5b611)",
      },
    },
    white: {
      background: "#FFFFFF",
      border: "1px solid #000",
      color: "#1D1D1D",
      fontFamily: "Avantt",
      // fontSize: "12.41px",

      fontSize: "14.41px",
      fontWeight: "600",
      transition: "all 0.2s ease-in-out",
      _hover: {
        background: "linear-gradient(#f5e003, #e5b611)",
      },
    },

    yellowSec: {
      background: "linear-gradient(#f5e003, #e6c200)",
      border: "1px solid #000",
      color: "#1D1D1D",
      // height: "36px",
      // fontSize: "12.41px",
      fontSize: "14.41px",

      fontFamily: "Avantt",
      fontWeight: "600",
      transition: "all 0.2s ease-in-out",
      _hover: {
        background: "white",
        color: "#1D1D1D",
      },
    },

    black: {
      background: "#000",
      color: "#fff",
      fontFamily: "Avantt",

      fontSize: "14px",
      fontWeight: "700",
      transition: "all 0.2s ease-in-out",
      _hover: {
        background: "linear-gradient(#f5e003, #e5b611)",
        color: "#000",
      },
    },
    yellow: {
      alignItems: "center",
      background: "linear-gradient(#f5e003,#e5b611);",
      borderRadius: "4px",
      fontFamily: "Avantt",

      color: "#000",
      display: "flex",
      fontSize: "14px",
      fontWeight: "700",
      gap: "2px",
      height: "30px",
      justifyContent: "center",
      minWidth: "160px",
      textTransform: "uppercase",
      transition: "all .2s ease-in-out",
    },
    darkblack: {
      alignItems: "center",
      background: "black",
      borderRadius: "4px",
      fontFamily: "Avantt",

      color: "#FFF",
      display: "flex",
      fontSize: "14px",
      fontWeight: "700",
      gap: "2px",
      height: "30px",
      justifyContent: "center",
      minWidth: "160px",
      textTransform: "uppercase",
      transition: "all .2s ease-in-out",
    },
  },
  defaultProps: {
    size: "md",
    variant: "primary",
  },
};

export default Button;
