import { Container, Img } from "@chakra-ui/react";
import gifSrc from "innovate/assets/loader/Innovate.ai.gif";
import React from "react";

interface LoaderProps {
  dH: string;
  dW: string;
}

const Loader: React.FC<LoaderProps> = ({ dH, dW }) => (
  <Container
    display="flex"
    justifyContent="center"
    alignItems="center"
    width={dW}
    height={dH}
  >
    <Img src={gifSrc} alt="Loading..." />
  </Container>
);

export default Loader;
