import { Flex, Image, useColorModeValue } from "@chakra-ui/react";
import logo from "innovate/assets/images/abi_logo.png";
import InnovateX from "innovate/assets/images/logo_icon.png";
import React from "react";

interface PublicHeaderProps {}
const PublicHeader: React.FC<PublicHeaderProps> = () => (
  <Flex
    position="sticky"
    top="0"
    zIndex="sticky"
    borderBottom="solid 1px"
    borderColor="border"
    width="100%"
    alignItems="center"
    justifyContent="space-between"
    padding="10px 24px"
    bg={useColorModeValue("black", "black.900")}
  >
    <Flex align="center" columnGap="30px">
      <Image w="106px" src={logo} alt="loginFootLogo" />
    </Flex>

    <Flex alignItems="center" columnGap="12px">
      <Image w="30.35px" src={InnovateX} alt="loginFootLogo" />
    </Flex>
  </Flex>
);

export default PublicHeader;
