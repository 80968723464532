import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import { FC } from "react";

import footerLogo from "../../assets/images/abi-logo.svg";
import logo from "../../assets/images/abi-logo.svg";
import bgLogin from "../../assets/images/home-bg1.png";
import yellowBar from "../../assets/images/yelloBar.svg";

const Unauthorized: FC<object> = () => (
  <Box>
    <Box>
      <Image width="80px" src={logo} alt="LOGO" marginX="auto" />
      <Heading as="h2" fontSize="20px" fontWeight="600" marginTop="10px">
        Innovation Tool
      </Heading>
      <Heading as="h1" marginTop="40px" marginBottom="0" fontSize="30px">
        Request Access
      </Heading>
      <Text
        fontFamily="'Open Sans', 'Avantt', 'light', sans-serif"
        marginY="40px"
        marginX="0"
      >
        You are not authorised to use this application please contact the Dev
        team for support!
      </Text>
      <Button variant="outline" colorScheme="yellow" mt={4}>
        Logout & Retry
      </Button>
      <Image
        position="absolute"
        bottom="-7px"
        left="50%"
        width="120px"
        transform="translateX(-50%)"
        src={yellowBar}
        alt="yellowBar"
      />
    </Box>
  </Box>
);
export default Unauthorized;
